import React from "react";
import TextPageTemplate from "../TextPageTemplate";
import evangeliumimg from "./Evangelium.jpg"

export default function Evangelium() {
  return (
    <TextPageTemplate
      title={"Az Evangélium"}
      paragraphs={[
        "Az Evangélium melyben a „Jó Hír Misszió” gyökerezik",
        "1. Jézus Krisztus",
        "A Biblia főszereplő Jézus Krisztus. A Biblia fő témája a kegyelemből fakadó Evangélium, amely kinyilvánítja a kereszten kiontott Jézus Krisztus véréért való hálát. Ezen áldozat által bűnbocsánatot kaptunk, igazak lettünk és a szent Isten gyermekei lettünk, így most szabadon bemehetünk az Ő örök királyságába. Az Ószövetség bizonyságot tesz Jézus Krisztusról, aki a jövőben újra el fog jönni. Az Újszövetség bizonyságot tesz Jézus Krisztusról, aki az Ószövetségben megírtak szerint eljött, hogy eltörölje minden bűnünket és feltámadjon. A Bibliában található események világosan rámutatnak Jézus Krisztus keresztjére. Máté Evangéliumában az 1:21 megmagyarázza számunkra Jézus nevének a jelentését: „Mert ő szabadítja meg az ő népét annak bűneiből.” Jézus neve által világos kinyilatkoztatást kaptunk, hogy miért is jött le Jézus Krisztus erre a földre. „És tudjátok, hogy ő azért jelent meg, hogy a mi bűneinket elvegye; és ő benne nincsen bűn.” (János első levele 3:5) Jézus kifejezetten azzal a céllal jött a földre, hogy megszabadítson bennünket a bűntől, amely a pusztulásba vezetett volna bennünket.",
        "2. Az üdvösség, avagy a bűnbocsánat",
        "Akkor beszélhetünk üdvözítésről, ha valaki olyan helyzetbe kerül, amelyből önmagát a saját ereje által nem képes kiszabadítani, így a szabadulást csak megmentője keze által nyerheti el. Jézus megváltóként jött el közénk a földre, hogy üdvözítsen bennünket a bűneinkből.",
        "„És nincsen senkiben másban idvesség: mert nem is adatott emberek között az ég alatt más név, mely által kellene nékünk megtartatnunk.” (Ap. Csel. 4:12) Saját cselekedetei által senki sem tud megszabadulni a bűneitől és Jézus Krisztus nélkül senki sem nyerhet örök életet. Mivel az üdvösség kizárólag csak az üdvözítőtől függ, ezért a szabadulásra várók igyekezetére egyáltalán semmi szükség nincsen. Más szavakkal élve, az üdvösség kizárólag csak a szabadító munkálkodása által jön létre, ezért az teljes egészében kegyelemből van.",
        "Mindezt nagyon szépen láthatjuk Lukács Evangéliumának 10. fejezetében, a rablók kezébe esett ember történetében, Máté Evangéliumának 28. fejezetében, a Jézus mellett keresztre feszített lator esetében, a János Evangéliumának 8. fejezetében található házasságtörő asszony történetében, valamint János Evangéliumának 5. fejezetében, a 38 éve betegen fekvő ember történetében. Ezen történetekben szereplő emberek szívének állapota arra mutat rá, hogy önmagukra támaszkodva, a saját erejükkel, önállóan semmit sem tudnak tenni. Ezért pusztán Isten kegyelmére vannak utalva és csak Isten irgalmasságára várakoznak. Jézus odalépett ezekhez az emberekhez és felruházta őket az Isten kegyelméből fakadó üdvösséggel.",
        "3. A bűn",
        "A Biblia elmagyarázza nekünk, hogy nem azért lettünk bűnösök, mert kicsi gyermekkorunk óta vétkezünk, hanem azért, Ádámtól, az első embertől származunk. Ádám hallgatott a Sátán szavára, aki becsapta őt. „Annakokáért, miképpen egy ember által jött be a világra a bűn és a bűn által a halál, és akképpen a halál minden emberre elhatott, mivelhogy mindenek vétkeztek.”",
        "(Róm. 5:12) „Mert miképpen egy embernek engedetlensége által sokan bűnösökké lettek.” (Róm. 5:19) Mivel minden ember Ádámtól származik, és mivel mi is benne voltunk Ádámban, így a mi sorsunk is egybeforrt Ádám sorsával, ezért Ádám engedetlensége a mi engedetlenségünk is lett. Amikor Ádám és Éva vétkeztek, akkor még csak két ember létezett ezen a földön. Azonban mivel ők ketten egy test voltak, ezért Ádám bűne az egész emberiség bűnévé vált. Amikor Ádám megtagadta Isten beszédét, vele együtt az egész emberiség is megtagadta azt. Amikor Ádám elhagyta Istent, vele együtt mi is elhagytuk őt. Ebből kifolyólag, Ádám halála ránk is éppen úgy vonatkozik. „Mert amiképpen Ádámban mindnyájan meghalnak…” (1Kor. 15:22)",
        "4. Bűnbánat",
        "A bűnbánat nem azt jelenti csupán, hogy kijavítjuk azt, amit rosszul tettünk. Ezért is kiemelkedően fontos a bűnbocsánat eredményeként megvalósuló igazi bűnbánat. Mi mindannyian attól az Ádámtól származunk, aki elhagyta Istent, így mindannyian a Sátán szolgáivá lettünk. Mivel „meghaltunk a bűneink miatt…” (Efez. 2:1) fel kellene ismernünk a lelki állapotunkat és el kellene hagynunk gonosz útjainkat. Ha már felismerjük és tudjuk, hogy a bűneink miatt halálra vagyunk ítélve, akkor a saját igyekezetünk és munkálkodásunk helyett keresni fogjuk azt az életet és kegyelmet, amelyet csak Isten tud megadni számunkra.",
        "Azok az emberek, akiknek a szívében még nem érkezett el a halál, azok továbbra is csak a saját munkálkodásukra és igyekezetükre támaszkodnak és azért igyekeznek, hogy a saját igazságukat érvényesítsék. Ugyanakkor azok, akik Isten Igéje által meghaltak, felismerik azt is, hogy az ő saját munkálkodásuk és igyekezetük haszontalan, ebből kifolyólag azt is világosan látják, hogy Isten feltétel nélküli irgalmasságára és kegyelmére kell támaszkodniuk. Ez valójában a megbánás kezdete. A Zsidókhoz írt levélben, a megbánást a „metanoia” szóval jelölték, ez pedig a szív változását volt hivatott kifejezni. Az igazi megbánás azt jelenti, hogy szívünk gőgös és gonosz állapotából – amely a Sátánt is félrevezette és elhitette vele, hogy egyedül is véghez tud vinni mindent – át kell mennünk Jézus azon alázatos szívének az állapotába, melyben önmagunktól semmit sem cselekedhetünk.",
        "5. A törvény",
        "Ha nem vesszük észre gyengeségeinket, nem jutunk el az Úr elé. Ezért adta Isten a törvényt. Manapság sokan félreértik a törvényt és úgy vélik, hogy annak megtartása a hit által adja a szellemi életet, s megpróbálunk a törvény munkája által igazakká válni. De nagyon fontos annak ismerete, miért adta Isten a törvényt. Pál nyíltan kimondja a Rómaiaknak írt levélben, a 3:20, 3:28 versekben, hogy nem válhatunk igazakká a törvény és a tízparancsolat által. Ha ez így van, miért adta Isten a törvényt, melynek nem engedelmeskedhetünk? Mert az ember Istentől függ, Isten nélkül velejéig romlott, és a saját jó cselekedeteiből nem igazulhat meg. Ez az, amiért szükségünk van Isten kegyelmére. Az emberek nem válthatják meg önmagukat. Ezért áll a törvény tükörként előttünk, hogy megmutassa a mivoltunkat és ezáltal válik vezetővé, irányítóvá a Jézus felé vezető úton.",
        "6. Az Evangélium",
        "„Mert az Istennek igazsága jelentetik ki abban hitből hitbe…” (Róm. 1:17) Miután Luther Márton elnyerte Isten igazságát ezen ige által, elindította a hit megújításának, a reformációnak folyamatát. Minden vallás, mely nélkülözi az Igét, pusztán az emberi igazságot hirdet. De Isten Igéje elutasítja az emberi igazságot. „Az Isten pedig a mi hozzánk való szerelmét abban mutatta meg, hogy mikor még bűnösök voltunk, Krisztus érettünk meghalt. Minekutána azért most megigazultunk az ő vére által…” (Róm. 5: 8-9 ) Csak Jézus Krisztus által nyerhetjük el az örök életet, aki megszabadított minket minden bűnünktől és megigazított bennünket. Aki hisz ebben, hit által nyithatja meg maga előtt a mennyország kapuját. Ez a kegyelem Evangéliuma, amit Pál apostol hirdetett.",
        "7. A bűnért való örök váltság",
        "Isten már az Ószövetségben is sok helyen és sokféle ábrázolás által tett tanúbizonyságot az Evangéliumról. „Minekutána az Isten sok rendben és sokféleképpen szólott hajdan az atyáknak a próféták által, ez utolsó időkben szólott nékünk Fia által…” (Zsid. 1: 1-2) Az egyik példája ennek a Mózes 4. könyvében található bűnért való áldozat leírása. Az Ószövetségben a fogyatkozás nélkül lévő áldozati bárányok, amelyeket az emberek bűneinek eltörléséért kellett megölni, Jézus Krisztus előképét jelentették, aki majdan kereszthalált hal a világ bűneiért. Más szavakkal élve, Jézus Krisztus igazi áldozati bárányként magára vette a világ bűneit, azt követően pedig keresztre feszítették. A kereszten így szólt: „Elvégeztetett” és meghalt. Jézus Krisztus kiontott vére által megbékéltünk és eggyé lettünk Istennel. Jézus halálát követően Isten a Zsidókhoz írt levélben megfogalmazott ígéretét adta nekünk: „Azután így szól: És az ő bűneikről és álnokságaikról többé meg nem emlékezem. Ahol pedig bűnök bocsánata vagyon, ott nincs többé bűnért való áldozat.” (Zsid. 10:17-18)"
      ]}
      id={"evangelium"}
      img={
        (<img src={evangeliumimg} alt={"Golgota"}/>)
      }
      imgAlign={"right"}
      imgWidth={15}
    />
  )
}